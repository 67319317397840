import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Select } from '../../Components'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${Colors.lightBorder}`,
    padding: '7rem 1rem 1rem',
    position: 'relative'
  },
  selectContainer: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    width: 200
  }
}))

function CouponUseChart(props) {
  const [year, setYear] = useState(moment().year())
  const { t } = useTranslation()
  const classes = useStyles()

  const getSeries = () => {
    const counts = []
    for (let month = 1; month <= 12; month++) {
      const monthData = props.data.find(item => item.month === month && item.year === year)
      if (monthData) {
        counts.push(monthData.count || 0)
      } else {
        counts.push(0)
      }
    }

    return [
      {
        name: t('coupon_uses'),
        data: counts
      }
    ]
  }

  const getOptions = () => {
    return {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: Array(12).fill(null).map((_, index) => t(`month_${index + 1}`)),
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  }

  const getYearOptions = () => {
    const currentYear = moment().year()
    let minYear = currentYear
    for (const item of props.data) {
      minYear = Math.min(item.year, minYear)
    }
    const options = []
    for (let i = minYear; i <= currentYear; i++) {
      options.push({
        value: i,
        label: `${i}`
      })
    }
    return options.reverse()
  }

  return (
    <div className={classes.container}>
      <div className={classes.selectContainer}>
        <Select
          value={year}
          options={getYearOptions()}
          onChange={setYear}
        />
      </div>
      <ReactApexChart
        key={`chart-${year}`}
        options={getOptions()}
        series={getSeries()}
        type='bar'
        height={350}
      />
    </div>
  )
}

export default CouponUseChart

