import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, Input, PageContainer, PageContent, PageActions } from '../../../Components'

const CreateCouponCategory = (props) => {
  const { couponStore } = useStore()

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (couponStore.createOk) {
      props.history.push('/coupons?tab=2')
    }
  }, [couponStore.createOk])

  const createCategory = () => {
    couponStore.createCategory({
      titleFi,
      titleEn
    })
  }

  const renderContent = () => {
    return (
      <>
        <Title title={t('category_details')} description={t('category_details_description')} />
        <Input label={t('name_fi')} value={titleFi} onChange={setTitleFi} />
        <Input label={t('name_en')} value={titleEn} onChange={setTitleEn} />
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={t('create_category')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <Button text={t('create_category')} onClick={createCategory} margin />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCouponCategory)
