import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import qs from 'qs'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter, useHistory } from 'react-router-dom'
import { PageHeader, CouponTable, CouponOrganizationTable, DataActions, CouponCategoryTable, PageContainer, PageContent, NotificationTable } from '../../../Components'
import { CouponSortOptions, UserSortOptions } from '../../../Constants'

function Coupons(props) {
  const { t } = useTranslation()
  const { couponStore } = useStore()

  let initialTab = parseInt(qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab || '0', 10)
  const history = useHistory()

  useEffect(() => {
    couponStore.getCoupons()
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getNotifications()
  }, [])

  const [tab, setTab] = useState(initialTab)

  const selectTab = (evt, value) => {
    const params = new URLSearchParams()
    params.append('tab', value)

    history.push({search: params.toString()})
    setTab(value)
  }

  const tabs = [
    { id: 0, label: t('coupons') },
    { id: 1, label: t('organizations') },
    { id: 2, label: t('categories') },
    { id: 3, label: t('notifications') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [filterValue, setFilterValue] = useState('active')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const toCreate = () => {
    if (tab === 0) {
      props.history.push('/coupons/add')
    } else if (tab === 1) {
      props.history.push('/coupons/add-organization')
    } else if (tab === 2) {
      props.history.push('/coupons/add-category')
    } else if (tab === 3) {
      props.history.push('/coupon-notifications/add')
    }
  }

  const getFilterOptions = () => {
    if (tab === 0 || tab === 3) {
      return [
        { label: t('view_active'), value: 'active' },
        { label: t('view_all'), value: 'all' },
        { label: t('view_deleted'), value: 'deleted' }
      ]
    }
    return null
  }

  /* Coupons */
  const getCoupons = () => {
    let coupons = couponStore.coupons
    if (searchValue) {
      coupons = coupons.filter((coupon) => {
        const { titleFi = '', titleEn = '' } = coupon
        const organizationName = get(coupon, 'organization.name', '')
        const targets = [titleFi, titleEn, organizationName]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      coupons = orderBy(coupons, 'createdAt', 'desc')
    } else if (sortValue === 'popularity') {
      coupons = orderBy(coupons, 'totalUseCount', 'desc')
    } else {
      coupons = orderBy(coupons, 'titleFi', 'asc')
    }

    // Filter
    coupons = coupons.filter(item => {
      if (filterValue === 'active') return item.active
      else if (filterValue === 'deleted') return !item.active
      return true
    })

    // Sort deleted last
    coupons = orderBy(coupons, 'active', 'desc')

    return coupons
  }

  /* Organizations */
  const getOrganizations = () => {
    let organizations = couponStore.organizations
    if (searchValue) {
      organizations = organizations.filter(org => {
        const { name, address, email, phone } = org
        const targets = [name, address, email, phone]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      organizations = orderBy(organizations, 'createdAt', 'desc')
    } else {
      organizations = orderBy(organizations, 'name', 'asc')
    }
    return organizations
  }

  /* Categories */
  const getCategories = () => {
    let categories = couponStore.categories
    if (searchValue) {
      categories = categories.filter(org => {
        const { titleFi, titleEn } = org
        const targets = [titleFi, titleEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      categories = orderBy(categories, 'createdAt', 'desc')
    } else {
      categories = orderBy(categories, 'titleFi', 'asc')
    }
    return categories
  }

  /* Coupon notifications */
  const getNotifications = () => {
    let notifications = couponStore.notifications
    if (searchValue) {
      notifications = notifications.filter((notification) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = notification
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      notifications = orderBy(notifications, 'createdAt', 'desc')
    } else {
      notifications = orderBy(notifications, 'titleFi', 'asc')
    }
    // Filter
    notifications = notifications.filter(item => {
      if (filterValue === 'active') return item.active
      else if (filterValue === 'deleted') return !item.active
      return true
    })

    // Sort deleted last
    notifications = orderBy(notifications, 'active', 'desc')

    return notifications
  }

  const renderTable = () => {
    if (tab === 1) {
      return <CouponOrganizationTable items={getOrganizations()} />
    } else if (tab === 2) {
      return <CouponCategoryTable items={getCategories()} />
    } else if (tab === 3) {
      return <NotificationTable items={getNotifications()} type='coupon' />
    }
    return <CouponTable items={getCoupons()} />
  }

  return (
    <>
      <PageHeader
        title={tabs.find(tb => tb.id === tab).label}
        tabs={tabs}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
        createText={[t('create_coupon'), t('create_organization'), t('create_category')][tab]}
      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={tab === 0 ? CouponSortOptions : UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
            filterOptions={getFilterOptions()}
            filterValue={filterValue}
            onFilterChange={setFilterValue}
          />
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Coupons))
