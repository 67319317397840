import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { CouponOrganizationForm, PageHeader, Button, PageContainer, PageContent, PageActions } from '../../../Components'
import { useParams } from 'react-router-dom'

const CreateCouponOrganization = (props) => {
  const { couponStore } = useStore()

  const { organizationId }: any = useParams()

  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [url, setUrl] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    couponStore.getOrganization(organizationId)
  }, [])
  useEffect(() => {
    if (couponStore.organization) {
      setName(get(couponStore.organization, 'name', null))
      setAddress(get(couponStore.organization, 'address', null))
      setLatitude(get(couponStore.organization, 'latitude', null))
      setLongitude(get(couponStore.organization, 'longitude', null))
      setEmail(get(couponStore.organization, 'email', null))
      setPhone(get(couponStore.organization, 'phone', null))
      setUrl(get(couponStore.organization, 'url', null))
    }
  }, [couponStore.organization])

  const updateOrganization = () => {
    couponStore.updateOrganization(organizationId, {
      name,
      address,
      latitude,
      longitude,
      email,
      phone,
      url
    })
  }

  const renderContent = () => {
    return (
      <CouponOrganizationForm
        name={name}
        setName={setName}
        address={address}
        setAddress={setAddress}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        url={url}
        setUrl={setUrl}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('edit_organization')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <Button text={t('save')} onClick={updateOrganization} margin />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCouponOrganization)
