import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class OrganizationStore {
  rootStore

  organizations = []
  organization = null
  organizationUsers = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getMyOrganization() {
    this.loading = true
    const response = await Api.getMyOrganization()
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.organization = response.data
    }
  }

  async updateMyOrganization(data) {
    this.loading = true
    const response = await Api.updateMyOrganization(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      this.organization = response.data
    }
  }

  async getOrganizations() {
    this.loading = true
    const response: any = await Api.getOrganizations()
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.organizations = response.data
    }
  }

  async getOrganization(id) {
    this.loading = true
    this.loading = false
  }

  async createOrganization(data) {
    this.loading = true
    const response: any = await Api.createOrganization(data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'organization_created')

    if (response.ok) {
      // TODO: Redirect?
    }
    this.loading = false
  }

  async updateOrganization(id, data) {
    this.loading = true
    const response = await Api.updateMyOrganization(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
  }

  async deactivateOrganization(id) {
    this.loading = true
    this.loading = false
  }
}
