import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor
  },
  cell: {
    fontWeight: 400,
    color: Colors.tableContentColor,
    fontSize: '1.125rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  }
}))

function DataTableRow (props: any) {
  const classes = useStyles()

  return (
    <TableRow classes={{ root: classes.root }} hover>
      {props.data.map((value, index) => (
        <TableCell key={index} classes={{ root: classes.cell }}>
          {value}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default DataTableRow
