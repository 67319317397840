import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { Button } from '..'
import AddIcon from '../../Assets/Icons/add-new.svg'
import EditIcon from '../../Assets/Icons/edit.svg'
import GridIcon from '../../Assets/Icons/grid.svg'
import GridAltIcon from '../../Assets/Icons/grid-alt.svg'
import ListIcon from '../../Assets/Icons/file-structure.svg'
import ListAltIcon from '../../Assets/Icons/file-structure-alt.svg'
import { ViewMode } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '0 3rem',
    backgroundColor: Colors.pageHeaderBackgroundColor,
    borderBottom: `1px solid ${Colors.lightBorder}`
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  title: {
    margin: 0,
    fontFamily: Fonts.heading,
    fontSize: '3rem',
    color: Colors.heading
  },
  cancelActionButton: {
    backgroundColor: Colors.red,
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tabRoot: {
    fontFamily: Fonts.heading,
    fontWeight: 500,
    color: Colors.heading,
    fontSize: '1.33rem',
    textTransform: 'none'
  },
  indicator: {
    height: 4
  },
  viewActions: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row'
  }
}))

export default function PageHeader(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderActionButton = () => {
    if (props.onCreateClick) {
      return (
        <Button
          text={props.createText || t('add_new')}
          onClick={props.onCreateClick}
          rightIcon={AddIcon}
        />
      )
    } else if (props.onEditClick) {
      return (
        <Button
          text={props.editMode ? t('close_edit') : t('edit')}
          onClick={props.onEditClick}
          buttonStyle={props.editMode ? classes.cancelActionButton : null}
          rightIcon={props.editMode ? null : EditIcon}
          secondary
        />
      )
    }
    return null
  }

  const renderTabs = () => {
    if (!props.tabs) return null
    return (
      <Tabs
        value={props.currentTab}
        onChange={props.onTabChange}
        classes={{ indicator: classes.indicator }}
        aria-label=''
      >
        {
          props.tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} classes={{ root: classes.tabRoot }} />
          ))
        }
      </Tabs>
    )
  }

  const renderViewOptions = () => {
    if (!props.viewOnGrid ||!props.viewOnList) return null
    return (
      <div className={classes.viewActions}>
        <IconButton onClick={props.viewOnList}>
          <img src={props.viewMode === ViewMode.List ? ListIcon : ListAltIcon} alt='list' />
        </IconButton>
        <IconButton onClick={props.viewOnGrid}>
          <img src={props.viewMode === ViewMode.Grid ? GridIcon : GridAltIcon} alt='grid' />
        </IconButton>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <h1 className={classes.title}>{props.title}</h1>
        {renderActionButton()}
      </div>
      <div className={classes.bottomRow}>
        {renderTabs()}
        {renderViewOptions()}
      </div>
    </div>
  )
}
