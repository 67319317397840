import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ButtonBase from '@material-ui/core/ButtonBase'
import logo from '../../Assets/Images/app_logo.png'
import CouponLogo from '../../Assets/Images/coupon_logo.png'
import * as get from 'lodash/get'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    width: 300,
    backgroundColor: Colors.sidebarBackgroundColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      width: 240
    },
    [theme.breakpoints.down('md')]: {
      width: 80
    }
  },
  drawerClose: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: 80,
    backgroundColor: Colors.sidebarBackgroundColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  logo: {
    marginTop: '2rem',
    marginBottom: '1.5rem',
    alignSelf: 'center',
    maxWidth: '60%',
    height: 'auto'
  },
  listItem: {
    height: '3.8rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  link: {
    height: '3.8rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '2rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      paddingLeft: 0
    }
  },
  linkClosed: {
    width: '100%',
    height: 80,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: 30,
    minWidth: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontFamily: Fonts.heading,
    fontWeight: 500,
    color: Colors.white,
    fontSize: '1.125rem',
    marginLeft: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      display: 'none'
    }
  }
}))

function Sidebar(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const open = props.open

  const renderLogo = () => {
    if (props.history && get(window, 'location.pathname', '').includes('coupon')) {
      return <img src={CouponLogo} alt='logo' className={classes.logo} />
    }
    return <img src={logo} alt='logo' className={classes.logo} />
  }

  const renderItemText = (text) => {
    if (!open) return null
    return (
      <ListItemText
        primary={t(text)}
        classes={{ root: classes.itemTextRoot, primary: classes.itemText }}
      />
    )
  }

  const renderItems = () => {
    return props.navigationItems.map((item) => {
      return (
        <ListItem key={item.url} classes={{ root: classes.listItem }} button disableGutters>
          <Link to={item.url} className={open ? classes.link : classes.linkClosed}>
            <ListItemIcon classes={{ root: classes.icon }}>
              <img src={item.icon} alt='icon' />
            </ListItemIcon>
            {renderItemText(item.text)}
          </Link>
        </ListItem>
      )
    })
  }

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })
      }}
      open={open}
    >
      <ButtonBase onClick={props.toggleDrawer}>
        {renderLogo()}
      </ButtonBase>
      <List disablePadding>{renderItems()}</List>
    </Drawer>
  )
}


export default withRouter(Sidebar)
