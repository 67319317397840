import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, PollGrid, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'

function Polls(props) {
  const { t } = useTranslation()
  const { pollStore } = useStore()

  useEffect(() => {
    pollStore.getPolls()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const [tab, setTab] = useState(0)
  const selectTab = (_, value) => {
    setTab(value)
  }

  const toCreate = () => {
    props.history.push('/polls/add')
  }

  /* Polls */
  const getPolls = () => {
    let polls = pollStore.polls
    if (searchValue) {
      polls = polls.filter((poll) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = poll
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      polls = orderBy(polls, 'createdAt', 'desc')
    } else {
      polls = orderBy(polls, 'titleFi', 'asc')
    }

    // Filter
    polls = polls.filter(item => {
      // Deleted
      if (tab === 4) return !item.active
      // Draft
      if (tab === 3) return item.draft

      // Dont display deleted or drafts for other tabs
      if (!item.active || item.draft) return false

      // Ended
      if (tab === 2) {
        return moment().isAfter(item.validUntil)
      }

      // Upcoming
      if (tab === 1) {
        return moment().isBefore(item.validFrom)
      }

      // Active
      if (tab === 0) {
        return moment().isAfter(item.validFrom) && moment().isBefore(item.validUntil)
      }

      return true
    })

    // Sort deleted last
    polls = orderBy(polls, 'active', 'desc')

    return polls
  }

  const renderGrid = () => {
    return <PollGrid items={getPolls()} />
  }

  return (
    <>
      <PageHeader
        title={t('polls')}
        onCreateClick={toCreate}
        tabs={[
          { id: 0, label: t('poll_tab_active') },
          { id: 1, label: t('poll_tab_upcoming') },
          { id: 2, label: t('poll_tab_ended') },
          { id: 3, label: t('poll_tab_drafts') },
          { id: 4, label: t('poll_tab_deleted') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}

      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
          {renderGrid()}
        </PageContent>
      </PageContainer>
    </>
  )
}
export default withRouter(observer(Polls))
