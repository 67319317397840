import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class AnalyticsStore {
  rootStore
  analytics = null
  involvementAnalytics = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getAnalytics() {
    this.loading = true
    const response = await Api.getAnalytics()
    if (response.ok) {
      this.analytics = response.data
    }
    this.loading = false
  }

  async getInvolvementAnalytics() {
    this.loading = true
    const response = await Api.getInvolvementAnalytics()
    if (response.ok) {
      this.involvementAnalytics = response.data
    }
    this.loading = false
  }
}
