import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { validateCoupon } from '../Utils/validation'

export default class CouponStore {
  rootStore
  coupons = []
  categories = []
  organizations = []
  coupon = null
  couponAnalytics = null
  category = null
  organization = null

  notifications = []

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getCoupons() {
    this.loading = true
    const response: any = await Api.getCoupons()
    this.loading = false
    if (response.ok) {
      this.coupons = response.data
    }
  }

  async getOrganizations() {
    this.loading = true
    const response: any = await Api.getCouponOrganizations()
    this.loading = false
    if (response.ok) {
      this.organizations = response.data
    }
  }

  async createCategory(data) {
    this.createOk = false
    this.loading = true
    const response: any = await Api.createCouponCategory(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'category_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }


  async createOrganization(data) {
    this.createOk = false
    this.loading = true
    const response: any = await Api.createCouponOrganization(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'organization_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async getOrganization(id) {
    this.loading = true
    const response: any = await Api.getCouponOrganization(id)
    this.loading = false
    if (response.ok) {
      this.organization = response.data
    }
  }

  async getCategories() {
    this.loading = true
    const response: any = await Api.getCouponCategories()
    this.loading = false
    if (response.ok) {
      this.categories = response.data
    }
  }

  async getCategory(id) {
    this.loading = true
    const response: any = await Api.getCouponCategory(id)
    this.loading = false
    if (response.ok) {
      this.category = response.data
    }
  }

  async getCoupon(id) {
    this.loading = true
    const response: any = await Api.getCoupon(id)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
  }

  async getCouponAnalytics(id) {
    this.loading = true
    const response: any = await Api.getCouponAnalytics(id)
    this.loading = false
    if (response.ok) {
      this.couponAnalytics = response.data
    }
  }

  async createCoupon(coupon) {
    this.createOk = false

    const validationError = validateCoupon(coupon)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true
    const response: any = await Api.createCoupon(coupon)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'coupon_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async updateCoupon(id, coupon) {
    this.loading = true
    const response: any = await Api.updateCoupon(id, coupon)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async updateCategory(id, category) {
    this.loading = true
    const response: any = await Api.updateCouponCategory(id, category)
    this.loading = false
    if (response.ok) {
      this.category = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async updateOrganization(id, organization) {
    this.loading = true
    const response: any = await Api.updateCouponOrganization(id, organization)
    this.loading = false
    if (response.ok) {
      this.organization = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async getNotifications() {
    this.loading = true
    const response: any = await Api.getCouponNotifications()
    this.loading = false
    if (response.ok) {
      this.notifications = response.data
    }
  }
}
