import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, Input, PageContainer, PageContent, PageActions } from '../../../Components'

const CouponCategory = (props) => {
  const { couponStore } = useStore()
  const { categoryId }: any = useParams()

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (couponStore.category) {
      setTitleFi(get(couponStore.category, 'titleFi', null))
      setTitleEn(get(couponStore.category, 'titleEn', null))
    }
  }, [couponStore.category])

  useEffect(() => {
    couponStore.getCategory(categoryId)
  }, [])

  const updateCategory = () => {
    couponStore.updateCategory(categoryId, {
      titleFi,
      titleEn
    })
  }

  const renderContent = () => {
    return (
      <>
        <Title title={t('category_details')} description={t('category_details_description')} />
        <div>
            <Input label={t('name_fi')} value={titleFi} onChange={setTitleFi} />
            <Input label={t('name_en')} value={titleEn} onChange={setTitleEn} />
        </div>
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={t('edit_category')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <Button text={t('save')} onClick={updateCategory} margin />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CouponCategory)
