import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'
import ProfileAlt from '../../Assets/Images/profile.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 310,
    height: 260,
    backgroundColor: Colors.cardBackgroundColor,
    borderRadius: 5,
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 220
    }
  },
  inactive: {
    opacity: 0.5
  },
  photo: {
    height: 130,
    width: 130,
    objectFit: 'cover',
    borderRadius: '100%',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      height: 90,
      width: 90
    }
  },
  name: {
    fontFamily: Fonts.heading,
    fontWeight: 700,
    fontSize: '1.25rem',
    color: Colors.black,
    marginBottom: 5
  },
  email: {
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.content,
    marginBottom: 8
  },
  role: {
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1rem',
    color: Colors.content
  }
}))

function UserCard (props) {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const isActive = user.active
  const onClick = () => props.onClick(user.id)

  return (
    <ButtonBase className={`${classes.container} ${!isActive ? classes.inactive : ''}`.trim()} onClick={onClick}>
      <img className={classes.photo} src={user.photo || ProfileAlt} alt='photo' />
      <p className={classes.name}>{user.firstName} {user.lastName}</p>
      <p className={classes.email}>{user.email}</p>
      <p className={classes.role}>{t(user.role)}</p>
    </ButtonBase>
  )
}

export default UserCard
