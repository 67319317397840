import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { Title, PageHeader, Button, CouponForm, CouponUseChart, PageContainer, PageActions, PageContent, Dialog } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { formatDateTime } from '../../../Utils/dateTime'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    width: '100%',
    borderBottom: `1px solid ${Colors.lightBorder}`
  }
}))

const Coupon = () => {
  const classes = useStyles()
  const { couponStore } = useStore()
  const { couponId }: any = useParams()

  useEffect(() => {
    couponStore.getCoupon(couponId)
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getCouponAnalytics(couponId)
  }, [])

  useEffect(() => {
    if (couponStore.coupon) {
      setTitleFi(get(couponStore.coupon, 'titleFi', null))
      setTitleEn(get(couponStore.coupon, 'titleEn', null))
      setContentFi(get(couponStore.coupon, 'contentFi', null))
      setContentEn(get(couponStore.coupon, 'contentEn', null))
      setUseTimes(get(couponStore.coupon, 'useTimes', null))
      setValidFrom(get(couponStore.coupon, 'validFrom', null) ? moment(couponStore.coupon.validFrom).format('YYYY-MM-DD') : null)
      setValidUntil(get(couponStore.coupon, 'validUntil', null) ? moment(couponStore.coupon.validUntil).format('YYYY-MM-DD') : null)
      setOrganizationId(get(couponStore.coupon, 'organization.id', null))
      setCategoryId(get(couponStore.coupon, 'category.id', null))
    }
  }, [couponStore.coupon])

  const [tab, setTab] = useState(0)

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)
  const [useTimes, setUseTimes] = useState(null)

  const [validFrom, setValidFrom] = useState(null)
  const [validUntil, setValidUntil] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)

  const [alertVisible, setAlertVisible] = useState(false)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const selectTab = (evt, value) => setTab(value)

  const handleSetValidFrom = (val) => setValidFrom(val)

  const handleSetValidUntil = (val) => setValidUntil(val)

  const { t } = useTranslation()

  const updateCoupon = () => {
    couponStore.updateCoupon(couponId, {
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      useTimes,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      organizationId,
      categoryId
    })
  }

  const toggleDelete = () => {
    closeAlert()
    couponStore.updateCoupon(couponId, { active: !couponStore.coupon.active })
  }

  const renderDelete = () => {
    if (couponStore.coupon) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={couponStore.coupon.active ? t('delete_coupon') : t('activate_coupon')}
            onClick={openAlert}
            secondary
            fullWidth
            margin
          />
        </div>
      )
    }
    return null
  }

  const renderCoupon = () => {
    return (
      <>
        <PageContent disabled={!get(couponStore.coupon, 'active')}>
          <Title title={t('coupon_details')} description={t('coupon_details_description')} />
          <CouponForm
            validFrom={validFrom}
            setValidFrom={handleSetValidFrom}
            validUntil={validUntil}
            setValidUntil={handleSetValidUntil}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentEn={contentEn}
            setContentEn={setContentEn}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            contentFi={contentFi}
            setContentFi={setContentFi}
            organizations={couponStore.organizations}
            categories={couponStore.categories}
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            useTimes={useTimes}
            setUseTimes={setUseTimes}
            submitted={true}
          />
        </PageContent>
        <PageActions>
          <p className={classes.text}>
            <span className={classes.boldText}>{t('last_modified')}: </span>
            {formatDateTime(get(couponStore.coupon, 'updatedAt'))}
          </p>
          <Button
            text={t('save')}
            onClick={updateCoupon}
            margin
            fullWidth
          />
          {renderDelete()}
        </PageActions>
      </>
    )
  }

  const renderAnalytics = () => {
    if (couponStore.couponAnalytics) {

      const couponInfo = `${get(couponStore.coupon, 'titleFi')} (${ get(couponStore.coupon, 'organization.name') })`

      return (
        <PageContent>
          <Title title={t('coupon_uses')} />
          <Title title={couponInfo} type='subtitle' />
          <CouponUseChart data={couponStore.couponAnalytics.usesByMonth} />
        </PageContent>
      )
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={t('coupon_details')}
        tabs={[
          { id: 0, label: t('coupon_details'), description: t('lorem') },
          { id: 1, label: t('coupon_analytics'), description: t('lorem') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        {tab === 0 ? renderCoupon() : renderAnalytics()}
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(couponStore.coupon, 'active') ? t('delete_coupon_title') : t('activate_coupon_title')}
        description={get(couponStore.coupon, 'active') ? t('delete_coupon_description') : t('activate_coupon_description')}
      />
    </>
  )
}

export default observer(Coupon)
