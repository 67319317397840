import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import NotificationTableRow from './NotificationTableRow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>({
  root: {
    maxWidth: 1400
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  }
}))

function NotificationTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <NotificationTableRow item={item} type={props.type} />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <>
      <Table
        classes={{ root: classes.root }}
      >
        <TableHeader columnNames={[t('title_fi'), t('title_en'), t('send_time')]} />
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_notifications')} />
    </>
  )
}

export default NotificationTable
