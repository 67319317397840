import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { CouponOrganizationForm, PageHeader, Button, PageContainer, PageActions, PageContent } from '../../../Components'

const CreateCouponOrganization = (props) => {
  const { couponStore } = useStore()

  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [url, setUrl] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (couponStore.createOk) {
      props.history.push('/coupons?tab=1')
    }
  }, [couponStore.createOk])

  const createOrganization = () => {
    couponStore.createOrganization({
      name,
      address,
      latitude,
      longitude,
      email,
      phone,
      url
    })
  }

  const renderContent = () => {
    return (
      <CouponOrganizationForm
        name={name}
        setName={setName}
        address={address}
        setAddress={setAddress}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        url={url}
        setUrl={setUrl}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('create_organization')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <Button text={t('create_organization')} onClick={createOrganization} margin />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCouponOrganization)
