import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class SessionStore {
  rootStore

  token = null
  user = null
  loading = null
  isLoggedIn = false
  initialized = false
  emailChange = null
  emailVerification = null
  passwordReset = null

  keepAliveStarted = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async login(email, password) {
    this.loading = true
    const response: any = await Api.login({ email, password })
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, 'wrong_username_or_password', false)
    if (response.ok) {
      this.user = response.data.user
      this.isLoggedIn = true
      this.startKeepAlive()
    }
  }

  async logout() {
    this.loading = true
    const response: any = await Api.logout()
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.isLoggedIn = false
    }
  }

  async requestPasswordReset(email) {
    this.loading = true
    const response: any = await Api.requestPasswordReset({ email })
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'request_password_reset_ok')
  }

  async resetPassword(code, password) {
    this.loading = true
    const response = await Api.resetPassword({ code, password })
    console.log(response)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'password_reset_success')
    this.loading = false
  }

  async getMe() {
    this.loading = true
    const response: any = await Api.getMe()
    this.loading = false
    if (response.ok) {
      this.user = response.data
      this.isLoggedIn = true
      this.startKeepAlive()
    }
    this.initialized = true
  }

  async updateMe(data) {
    this.loading = true
    const response = await Api.updateMe(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'information_updated')
    if (response.ok) {
      this.user = response.data
    }
  }

  async createEmailChange(email) {
    this.loading = true
    const response = await Api.createEmailChange({ email })
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'check_inbox_to_complete_email_change')
    this.loading = false
  }

  async getEmailChange(code) {
    this.loading = true
    this.emailChange = null
    const response: any = await Api.getEmailChange(code)
    this.loading = false
    // this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', false)
    if (response.ok) {
      this.emailChange = response.data
    }
  }

  async verifyEmailChange(code) {
    this.loading = true
    const response = await Api.verifyEmailChange({ code })
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', 'email_changed')
  }

  async getPasswordReset(code) {
    this.loading = true
    this.passwordReset = null
    const response: any = await Api.getPasswordReset(code)
    this.loading = false
    if (response.ok) {
      this.passwordReset = response.data
    }
  }

  async changePassword(currentPassword, newPassword, newPasswordAgain) {
    if (!currentPassword || !newPassword || !newPasswordAgain) {
      return this.rootStore.appStore.setError('fill_all_fields')
    }
    else if (newPassword.length < 8) {
      return this.rootStore.appStore.setError('password_too_weak')
    } else if (newPassword !== newPasswordAgain) {
      return this.rootStore.appStore.setError('passwords_do_not_match')
    }
    this.loading = true
    const response: any = await Api.changePassword({ currentPassword, newPassword })
    if (response.ok) {
      // Logout other devices
      await Api.logoutOthers()
    }
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, 'check_fields_and_try_again', 'password_changed')
  }

  async getUserInvite(code) {
    this.loading = true
    const response: any = await Api.getUserInvite(code)
    this.loading = false
    // this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', false)
    if (response.ok) {
      this.emailVerification = true
    }
  }

  async verifyUserInvite(code, password) {
    this.loading = true
    const response: any = await Api.verifyUserInvite({ code, password })
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'verify_user_invite_success')
    if (response.ok) {
      // Redirect to login?
    }
  }

  async runKeepAlive() {
    if (this.isLoggedIn && this.initialized) {
      const result = await Api.keepAuthAlive()
      if (result.status === 401) {
        this.user = null
        this.isLoggedIn = false
      }
    }
  }

  async startKeepAlive() {
    if (!this.keepAliveStarted) {
      this.keepAliveStarted = true
      this.runKeepAlive()
      setInterval(() => {
        this.runKeepAlive()
      }, 1000 * 60 * 60 * 1) // Run every 1 hour
    }
  }
}
