import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'

enum TitleTypes {
  Title = 'title',
  Main = 'main',
  Subtitle = 'subtitle'
}

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    margin: 0,
    fontFamily: Fonts.heading,
    fontSize: '2.625rem',
    fontWeight: 700,
    color: Colors.heading,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
      marginBottom: '1.25rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.25rem',
      marginBottom: '1rem'
    }
  },
  title: {
    margin: 0,
    fontFamily: Fonts.heading,
    fontSize: '2.625rem',
    fontWeight: 400,
    color: Colors.heading,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
      marginBottom: '1.25rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.25rem',
      marginBottom: '1.25rem'
    }
  },
  subtitle: {
    margin: 0,
    fontFamily: Fonts.heading,
    fontSize: '1.875rem',
    fontWeight: 400,
    color: Colors.heading,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.75rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  description: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    maxWidth: 700,
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: 600,
      fontSize: '1rem',
      marginBottom: '1.25rem'
    }
  }
}))

export default function Title(props) {
  const classes = useStyles()

  const renderTitle = () => {
    const { type, title } = props
    if (type && type === TitleTypes.Main) {
      return <h1 className={classes.mainTitle}>{title}</h1>
    }
    if (type && type === TitleTypes.Subtitle) {
      return <h3 className={classes.subtitle}>{title}</h3>
    }
    return <h2 className={classes.title}>{title}</h2>
  }

  const renderDescription = () => {
    if (props.description) {
      const { descriptionStyle = {} } = props
      return (
        <p className={clsx(classes.description, descriptionStyle)}>
          {props.description}
        </p>
      )
    }
    return null
  }

  return (
    <>
      {props.spacer && <div style={{ height: props.spacer }} />}
      {renderTitle()}
      {renderDescription()}
    </>
  )
}
