import React from 'react'
import { Input, Select } from '..'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '../../Assets/Icons/search.svg'

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 1400
  },
  searchContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 500,
    marginRight: '2.5rem'
  },
  sortContainer: {
    marginRight: '.5rem'
  },
  spacer: {
    flex: 1
  },
  searchIcon: {
    position: 'absolute',
    top: '1rem',
    right: '.8rem',
    opacity: 0.66,
    height: '1.25rem',
    userSelect: 'none',
    pointerEvents: 'none'
  }
})

function DataActions (props) {
  const classes = useStyles()

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.searchContainer}>
        <Input
          placeholder={props.searchPlaceholder}
          value={props.searchValue}
          onChange={props.onSearchChange}
        />
        <img src={SearchIcon} className={classes.searchIcon} alt='search' />
      </div>
      <div className={classes.spacer} />
      {!(props.filterOptions && props.filterOptions.length) ? null : (
        <div className={classes.sortContainer}>
          <Select
            options={props.filterOptions}
            value={props.filterValue}
            onChange={props.onFilterChange}
          />
        </div>
      )}
      {!(props.sortOptions && props.sortOptions.length) ? null : (
        <div className={classes.sortContainer}>
          <Select
            options={props.sortOptions}
            value={props.sortValue}
            onChange={props.onSortChange}
          />
        </div>
      )}
    </div>
  )
}

export default DataActions
