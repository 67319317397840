import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '2.25rem',
    borderRadius: 5,
    cursor: 'pointer',
    background: Colors.white,
    padding: '0 .5rem',
    alignItems: 'center',
    marginLeft: '.75rem'
  },
  active: {
    display: 'block',
    fontWeight: 700,
    fontSize: '1.125rem'
  },
  inactive: {
    display: 'block',
    fontWeight: 300,
    fontSize: '1.125rem'
  },

  redLight: {
    background: Colors.red,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  greenLight: {
    background: Colors.green,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  yellowLight: {
    background: Colors.yellow,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  }

}))

function PollLangButton (props) {
  const classes = useStyles()

  const renderStatus = () => {
    const { status } = props
    if (status === 'red') {
      return <div className={classes.redLight} />
    } else if (status === 'green') {
      return <div className={classes.greenLight} />
    } else if (status === 'yellow') {
      return <div className={classes.yellowLight} />
    }
    return null
  }

  return (
    <div className={classes.container} onClick={props.onClick}>
      <span className={props.active ? classes.active : classes.inactive}>
       {props.lang === 'fi' ? 'FI' : 'ENG'}
     </span>
     {renderStatus()}
    </div>
  )
}

export default PollLangButton
