import i18n from '../Localization'
import users from '../Assets/Icons/user.svg'
import analytics from '../Assets/Icons/analytics.svg'
import settings from '../Assets/Icons/settings.svg'
import notifications from '../Assets/Icons/notifications.svg'
import coupon from '../Assets/Icons/coupon.svg'
import feedback from '../Assets/Icons/feedback.svg'
import poll from '../Assets/Icons/poll.svg'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBRt7z7B5ec5ja5Rf2pdSOHQdjzWbozaJk'

export const CITY_LOCATION = {
  latitude: 62.60102331291274,
  longitude: 29.763572181810904,
  radius: 30000
}

export enum Role {
  Admin = 'admin',
  User = 'user'
}
export const ROLES = ['admin', 'user']

export const ACCESS_RIGHTS = ['coupon', 'notification', 'poll', 'influence', 'analytics']

export const AvailableRoutes = (user) => {
  if (!user || !user.role) return []
  const { role, accessRights } = user

  const accessCheck = (accessRight, data) => {
    if (role === 'admin') return data
    if (accessRights && accessRights.includes(accessRight)) {
      return data
    }
    return null
  }

  return [
    accessCheck('coupon', { url: '/coupons', icon: coupon, text: 'coupons' }),
    accessCheck('poll', { url: '/polls', icon: poll, text: 'polls' }),
    accessCheck('notification', { url: '/notifications', icon: notifications, text: 'notifications' }),
    accessCheck('influence', { url: '/feedbacks', icon: feedback, text: 'feedbacks' }),
    accessCheck('analytics', { url: '/analytics', icon: analytics, text: 'analytics' }),
    accessCheck('admin', { url: '/users', icon: users, text: 'users' }),
    { url: '/settings', icon: settings, text: 'settings' }
  ].filter(item => !!item)
}

export const UserSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const CouponSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' },
  { id: 3, label: i18n.t('sort_by_popularity'), value: 'popularity' }
]

export const OrganizationSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export enum ViewMode {
  List = 'list',
  Grid = 'grid'
}
