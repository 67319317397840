import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  }
}))

function CouponOrganizationTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const handleClick = () => props.history.push(`/coupons/organization/${item.id}`)

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell onClick={handleClick} className={`${classes.cell} ${classes.firstCell}`}>
        <div className={classes.row}>
          <span>{item.name}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'address')}</TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'email')}</TableCell>
      <TableCell onClick={handleClick} className={`${classes.cell} ${classes.lastCell}`}>{get(item, 'phone')}</TableCell>
    </TableRow>
  )
}

export default withRouter(CouponOrganizationTableRow)
