import moment from 'moment'

export const formatDateTime = (dateISOString: string) => {
  return moment(dateISOString).format('DD.MM.YYYY HH:mm')
}

export const formatDatePeriod = (startDateString: string, endDateString: string) => {
  if (startDateString && endDateString) {
    return `${moment(startDateString).format('DD.MM.YYYY')} - ${moment(endDateString).format('DD.MM.YYYY')}`
  }
  if (endDateString) {
    return `${moment(endDateString).format('DD.MM.YYYY')}`
  }
}
