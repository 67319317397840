import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { validatePoll } from '../Utils/validation'

export default class PollStore {
  rootStore
  polls = []
  poll = null
  pollAnswers = null

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getPolls() {
    this.loading = true
    const response: any = await Api.getPolls()
    this.loading = false
    if (response.ok) {
      this.polls = response.data
    }
  }

  async getPoll(id) {
    this.loading = true
    const response: any = await Api.getPoll(id)
    this.loading = false
    if (response.ok) {
      this.poll = response.data
    }
  }

  async getPollAnswers(id) {
    this.loading = true
    const response: any = await Api.getPollAnswers(id)
    this.loading = false
    if (response.ok) {
      this.pollAnswers = response.data
    }
  }

  async updatePoll(id, poll) {

    const validationError = validatePoll(poll)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true
    const response: any = await Api.updatePoll(id, poll)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.poll = response.data
    }
  }

  async createPoll(poll) {
    this.createOk = false

    const validationError = validatePoll(poll)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true
    const response: any = await Api.createPoll(poll)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async exportPoll(id) {
    Api.getPollExport(id)
  }
}
