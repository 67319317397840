import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  }
}))

function CouponCategoryTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const handleClick = () => props.history.push(`/coupons/category/${props.item.id}`)

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell onClick={handleClick} className={`${classes.cell} ${classes.firstCell}`}>
        <div className={classes.row}>
          <span>{item.titleFi}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={`${classes.cell} ${classes.lastCell}`}>{item.titleEn}</TableCell>
    </TableRow>
  )
}

export default withRouter(CouponCategoryTableRow)
