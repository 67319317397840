import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800
  },
  description: {
    marginBottom: '3rem'
  },
  backLink: {
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem'
  }
}))

const ForgotPassword = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  const [email, setEmail] = useState(null)

  const requestPasswordReset = () => sessionStore.requestPasswordReset(email)

  return (
    <div className={classes.root}>
      <Title
        title={t('forgot_password')}
        description={t('forgot_password_description')}
        type='main'
        descriptionStyle={classes.description}
      />
      <Input label={t('email')} value={email} onChange={setEmail} autoFocus />
      <Button text={t('send_request')} onClick={requestPasswordReset} margin />

      <Link className={classes.backLink} to='/'>{t('back_to_login_page')}</Link>    </div>
  )
}

export default observer(ForgotPassword)
