import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ProfileAlt from '../../Assets/Images/profile.png'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '4.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 3rem',
    backgroundColor: Colors.pageHeaderBackgroundColor,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('md')]: {
      height: '4rem',
      padding: '0 2rem'
    }
  },
  profileButton: {
    padding: 0
  },
  profilePicture: {
    height: 56,
    width: 56,
    borderRadius: 28,
    objectFit: 'cover',
    border: `3px solid ${Colors.lightBorder}`,
    [theme.breakpoints.down('lg')]: {
      height: 52,
      width: 52,
      borderRadius: 26
    },
    [theme.breakpoints.down('md')]: {
      height: 48,
      width: 48,
      borderRadius: 24
    }
  }
}))

export default function Header(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  // Return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }
    prevOpen.current = open
  }, [open])

  const renderProfilePicture = () => {
    if (props.user) {
      const photo = props.user.photo || ProfileAlt
      return (
        <IconButton
          ref={anchorRef}
          aria-controls='profile-menu'
          aria-haspopup='true'
          onClick={handleToggle}
          classes={{ root: classes.profileButton }}
        >
          <img src={photo} className={classes.profilePicture} alt='profile' />
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id='profile-menu' onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={props.logout}>{t('logout')}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </IconButton>
      )
    }
    return null
  }

  return (
    <div>
      <AppBar position='static' className={classes.appBar}>
        <Toolbar disableGutters>
          {renderProfilePicture()}
        </Toolbar>
      </AppBar>
    </div>
  )
}
