import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    flex: 1,
    padding: '3rem',
    paddingBottom: '20rem',
    [theme.breakpoints.down('xl')]: {
      padding: '2rem 3rem',
      paddingBottom: '20rem'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2rem',
      paddingBottom: '20rem'
    }
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.deletedOverlayColor,
    zIndex: 5
  }
}))

const PageContent = (props) => {
  const classes = useStyles()

  const renderDisabled = () => {
    if (props.disabled) {
      return <div className={classes.disabledOverlay} />
    }
    return null
  }

  return (
    <div className={classes.container}>
      <div>
        {props.children}
      </div>
      {renderDisabled()}
    </div>
  )
}

export default PageContent
