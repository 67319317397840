import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDatePeriod } from '../../Utils/dateTime'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  truncated: {
    maxWidth: '16rem',
    maxHeight: '4em',
    overflow: 'hidden'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 700
  },
  categoryBadge: {
    background: 'rgba(0, 0, 0, .03)',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: 5,
    padding: '.5rem .75rem'
  }
}))

function CouponTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const handleClick = () => props.history.push(`/coupons/${item.id}`)

  const statusClass = item.active ? '' : classes.deleted
  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        <div className={classes.truncated}>
          <span>{item.titleFi}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'totalUseCount')}</TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        <div className={classes.categoryBadge}>
          {get(item, 'category.titleFi')}
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'organization.name')}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.lastCell].join(' ')}>{formatDatePeriod(item.validFrom, item.validUntil)}</TableCell>
    </TableRow>
  )
}

export default withRouter(CouponTableRow)
