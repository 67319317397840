import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import ProfileAlt from '../../Assets/Images/profile.png'
import MailIcon from '../../Assets/Icons/mail.svg'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  link: {
    textDecoration: 'none',
    color: Colors.black
  },
  photo: {
    height: 40,
    width: 40,
    objectFit: 'cover',
    borderRadius: '100%',
    marginRight: '1rem',
    [theme.breakpoints.down('md')]: {
      height: 32,
      width: 32
    }
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 32,
    height: 32,
    borderRadius: 5,
    marginRight: '1rem'
  }
}))

function UserTableRow (props) {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = () => props.history.push(`/users/${user.id}`)

  const statusClass = user.active ? '' : classes.deleted

  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        <div className={classes.row}>
          <img className={classes.photo} src={user.photo || ProfileAlt} alt='photo' />
          <span>{user.firstName} {user.lastName}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>{user.email}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>{t(user.role)}</TableCell>
      <TableCell className={[classes.cell, classes.lastCell].join(' ')}>
        <a className={classes.buttonLink} href={ `mailto:${user.email}`}>
          <img src={MailIcon} alt='email' />
        </a>
      </TableCell>
    </TableRow>
  )
}

export default withRouter(UserTableRow)
