import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../../../Models/RootStore'
import { Colors } from '../../../Utils/theme'
import DownloadsChart from '../../../Components/Analytics/DownloadsChart'
import { PageContainer, PageContent, Title, Map, PageHeader } from '../../../Components'
import { sortBy } from 'lodash'

const useStyles = makeStyles({
  pageContentInner: {
    maxWidth: 1200
  },
  monthlyContainer: {
    padding: '.75rem'
  },
  topCotainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem'
  },
  countCard: {
    flex: 1,
    textAlign: 'center',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1rem 2rem',
    margin: '.5rem'
  },
  countTitle: {
    fontWeight: 700
  },
  countValue: {
    fontSize: '3rem',
    color: Colors.primaryButtonColor
  },
  countFooter: {
    opacity: 0.6
  },
  row: {
    flexDirection: 'row',
    display: 'flex'
  },
  cardContainer: {
    flex: 1,
    border: `1px solid ${Colors.lightBorder}`,
    padding: `1.5rem 2rem 2.5rem`,
    margin: '.5rem'
  },
  cardTitle: {
    fontSize: '1.5rem'
  },
  listRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${Colors.lightBorder}`,
    paddingBottom: '.5rem',
    paddingTop: '1rem'
  },
  listRowLast: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '.5rem',
    paddingTop: '1rem'
  },
  listIndex: {
    color: Colors.primaryButtonColor,
    fontWeight: 700,
    fontSize: '1.125rem',
    paddingRight: '1rem'
  },
  listTitle: {
    color: Colors.content,
    flex: 1,
    fontSize: '1.125rem',
    paddingRight: '1rem'
  },
  listCount: {
    color: Colors.primaryButtonColor,
    fontSize: '1.125rem'
  },
  mapContainer: {
    padding: '.125rem'
  }
})

function Analytics() {
  const classes = useStyles()
  const { t } = useTranslation()

  const { analyticsStore, pollStore } = useStore()

  const [tab, setTab] = useState(0)

  const selectTab = (evt, value) => setTab(value)

  useEffect(() => {
    analyticsStore.getAnalytics()
    analyticsStore.getInvolvementAnalytics()
    pollStore.getPolls()
  }, [])

  const renderDownloadCounts = () => {
    if (analyticsStore.analytics) {
      return (
        <div className={classes.topCotainer}>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('all_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.totalDownloads}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('android_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.androidDownloads}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('ios_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.iosDownloads}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('downloads_today')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.todayDownloads}</p>
          </div>
        </div>
      )
    }
    return null
  }

  const renderInvolvementCounts = () => {
    if (analyticsStore.involvementAnalytics) {

      return (
        <div className={classes.topCotainer}>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('poll_answer_count')}</p>
            <p className={classes.countValue}>{analyticsStore.involvementAnalytics.pollAnswerCount}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('city_feedback_count')}</p>
            <p className={classes.countValue}>{analyticsStore.involvementAnalytics.cityFeedbackCount}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('fault_report_count')}</p>
            <p className={classes.countValue}>{analyticsStore.involvementAnalytics.faultReportCount}</p>
          </div>
        </div>
      )
    }
    return null
  }

  const renderTopPolls = () => {
    const limit = 5
    const topPolls = sortBy((pollStore.polls || []), 'totalAnswers').slice(-limit).reverse()
    return topPolls.map((poll, index) => {
      const title = poll.titleFi.split('\n')[0]
      return (
        <div className={index !== limit - 1 ? classes.listRow : classes.listRowLast}>
          <div className={classes.listIndex}>{index + 1}.</div>
          <div className={classes.listTitle}>{title}</div>
          <div className={classes.listCount}>{poll.totalAnswers} {t('n_answers')}</div>
        </div>
      )
    })
  }

  const renderFaultReportMap = () => {
    const points = get(analyticsStore, 'involvementAnalytics.faultReportPoints')
    if (!points) return null
    return (
      <div className={classes.cardContainer}>
        <Title title={t('fault_reports_on_map')} type='subtitle' />
        <div className={classes.mapContainer}>
          <Map points={points} />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (tab === 1) {

      return (
        <>
          <Title title={t('involvement_stats')} type='subtitle' />
          {renderInvolvementCounts()}
          <div className={classes.row}>
            <div className={classes.cardContainer}>
              <p className={classes.cardTitle}>{t('top_5_polls')}</p>
              {renderTopPolls()}
            </div>
          </div>
          {renderFaultReportMap()}

        </>
      )
    }


    return (
      <>
        {renderDownloadCounts()}
        <div className={classes.monthlyContainer}>
          <Title title={t('new_user_downloads')} type='subtitle' />
          <DownloadsChart data={analyticsStore.analytics.monthlyDownloads} />
        </div>
      </>
    )
  }

  if (!analyticsStore.analytics) return null

  return (
    <>
      <PageHeader
        title={t('analytics')}
        tabs={[
          { id: 0, label: t('downloads') },
          { id: 1, label: t('involvement') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>
          <div className={classes.pageContentInner}>
            {renderContent()}
          </div>
        </PageContent>
      </PageContainer>
    </>
  )
}

export default observer(Analytics)
