import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800
  },
  description: {
    marginBottom: '3rem'
  },
  backLink: {
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem'
  }
}))

const ResetPassword = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (initialCode) {
      sessionStore.getPasswordReset(initialCode)
    }
  }, [])

  const [password, setPassword] = useState('')

  const resetPassword = () => {
    sessionStore.resetPassword(initialCode, password)
    setPassword('')
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('reset_password')}
        description={initialCode && !!sessionStore.passwordReset ? t('reset_password_description') : t('invalid_or_expired_code_description')}
        type='main'
        descriptionStyle={classes.description}
      />
      { initialCode && !!sessionStore.passwordReset && (
        <Input
          label={t('password')}
          value={password}
          onChange={setPassword}
          type='password'
          autoFocus
        />
      )}
      {initialCode && !!sessionStore.passwordReset && <Button text={t('set_password')} onClick={resetPassword} margin />}

      <Link className={classes.backLink} to='/'>{t('back_to_login_page')}</Link>
    </div>
  )
}

export default observer(ResetPassword)
