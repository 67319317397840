import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class NotificationStore {
  rootStore
  notifications = []
  notification = null

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getNotifications() {
    this.loading = true
    const response: any = await Api.getNotifications()
    this.loading = false
    if (response.ok) {
      this.notifications = response.data
    }
  }

  async getNotification(id) {
    this.loading = true
    const response: any = await Api.getNotification(id)
    this.loading = false
    if (response.ok) {
      this.notification = response.data
    }
  }

  async updateNotification(id, notification) {
    this.loading = true
    const response: any = await Api.updateNotification(id, notification)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.notification = response.data
    }
  }

  async createNotification(notification) {
    this.createOk = false
    this.loading = true
    const response: any = await Api.createNotification(notification)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'notification_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }
}
