import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDateTime } from '../../Utils/dateTime'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 700
  },
  sent: {
    color: Colors.green
  }
}))

function NotificationTableRow (props) {
  const { item }: any = props
  const classes = useStyles()

  const handleClick = () => {
    if (item.type === 'coupon') {
      props.history.push(`/coupon-notifications/${item.id}`)
    } else {
      props.history.push(`/notifications/${item.id}`)
    }
  }
  const statusClass = item.active ? '' : classes.deleted

  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        {item.titleFi}
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>{item.titleEn}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.lastCell, item.sentAt ? classes.sent : ''].join(' ')}>
        {formatDateTime(item.publishedAt)}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(NotificationTableRow)
