import apisauce from 'apisauce'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/',
  Stage: 'https://joensuu-app-api.stage.geniem.io/',
  Production: 'https://joensuu-app-api.production.geniem.io/'
}

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

// Select API URL to use
let apiUrl = API_URLS.Production
if (process.env.NODE_ENV === 'development') apiUrl = API_URLS.Local
if (window.location.hostname.includes('.stage.geniem.io')) apiUrl = API_URLS.Stage

const create = () => {
  const api = apisauce.create({
    baseURL: apiUrl,
    headers: {},
    timeout: 20000,
    withCredentials: true
  })

  return {
    // Auth
    login: (data) => api.post('auth/login', data),
    logout: () => api.put('auth/logout'),
    logoutOthers: () => api.put('auth/logout-others'),
    requestPasswordReset: (data) => api.post('auth/password-reset', data),
    getPasswordReset: (code) => api.get(`auth/password-reset/${code}`),
    resetPassword: (data) => api.put('auth/password-reset', data),
    inviteUser: (data) => api.post('auth/email-invite', data),
    verifyUserInvite: (data) => api.put('auth/email-invite', data),
    createEmailChange: (data) => api.post('auth/email-change', data),
    getEmailChange: (code) => api.get(`auth/email-change/${code}`),
    verifyEmailChange: (data) => api.put('auth/email-change', data),
    getUserInvite: (code) => api.get(`auth/email-invite/${code}`),
    getUserInvites: () => api.get('auth/invited'),
    keepAuthAlive: () => api.put('auth/keep-alive'),

    // User
    getMe: () => api.get('user/me'),
    updateMe: (data) => api.put('user/me', data),
    updateUser: (id, data) => api.put(`user/${id}`, data),
    changePassword: (data) => api.put('user/me/password', data),
    getMyOrganizationUsers: () => api.get('user'),
    getMyOrganizationUser: (id) => api.get(`user/${id}`),
    createUser: (data) => api.post('user', data),
    deactivateUser: (id: number) => api.delete(`user/${id}`),
    getUser: (id) => api.get(`user/${id}`),

    // Organization
    getMyOrganization: () => api.get('organization/my'),
    updateMyOrganization: (data) => api.put('organization/my', data),
    getOrganizations: () => api.get('organization'),
    getOrganization: (id: number) => api.get(`organization/${id}`),
    createOrganization: (data) => api.post('organization', data),
    updateOrganization: (id: number, data) => api.put(`organization/${id}`, data),

    // Entities
    getMainEntities: () => api.get('main-entity'),

    // Coupons
    getCouponNotifications: () => api.get('notification/coupon'),
    getCoupons: () => api.get('coupon'),
    getCoupon: (couponId) => api.get(`coupon/${couponId}`),
    getCouponAnalytics: (couponId) => api.get(`coupon/analytics/${couponId}`),
    getCouponCategory: (id) => api.get(`coupon/category/${id}`),
    getCouponOrganization: (id) => api.get(`coupon/organization/${id}`),
    createCoupon: (coupon) => api.post('coupon', coupon),
    updateCoupon: (couponId, coupon) => api.put(`coupon/${couponId}`, coupon),
    updateCouponCategory: (id, category) => api.put(`coupon/category/${id}`, category),
    updateCouponOrganization: (id, organization) => api.put(`coupon/organization/${id}`, organization),
    getCouponOrganizations: () => api.get('coupon/organizations'),
    getCouponCategories: () => api.get('coupon/categories'),
    createCouponOrganization: (data) => api.post('coupon/organization', data),
    createCouponCategory: (data) => api.post('coupon/category', data),

    // Notificaitons
    getNotifications: () => api.get('notification'),
    getNotification: (id) => api.get(`notification/${id}`),
    createNotification: (notification) => api.post('notification', notification),
    updateNotification: (id, notification) => api.put(`notification/${id}`, notification),

    // Influence / feedbacks
    getCityFeedbacks: () => api.get('influence/city-feedback'),
    getCityFeedback: (id) => api.get(`influence/city-feedback/${id}`),
    getFaultReports: () => api.get('influence/fault-report'),
    getFaultReport: (id) => api.get(`influence/fault-report/${id}`),

    // Polls
    getPolls: () => api.get('poll/all'),
    getPollAnswers: (id) => api.get(`poll/answers/${id}`),
    getPoll: (id) => api.get(`poll/${id}`),
    updatePoll: (id, poll) => api.put(`poll/${id}`, poll),
    createPoll: (poll) => api.post('poll', poll),
    getPollExport: (id) => window.open(`${apiUrl}poll/export/${id}`),

    // Analytics
    getAnalytics: () => api.get('analytics'),
    getInvolvementAnalytics: () => api.get('analytics/involvement'),

    // Logs
    getMyLoginLog: (params = {}) => api.get('log/my/login', params),

    getMediaUrl: (id) => `${apiUrl}media/${id}`
  }
}

const Api = create()

export default Api
