import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function BaseDialog(props) {
  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'
      >
        <DialogTitle id='dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='dialog-description'>
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            {props.dismissText || t('cancel')}
          </Button>
          <Button onClick={props.handleOk} color='primary' autoFocus>
            {props.okText || t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
