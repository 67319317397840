import React  from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AuthLayout } from '../../Layouts'
import { Login, ForgotPassword, VerifyEmail, EmailChange, ResetPassword } from './Auth'

export default function Unauthorized() {
  return (
    <AuthLayout>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route exact path='/verify-email' component={VerifyEmail} />
        <Route exact path='/email-change' component={EmailChange} />
        <Redirect from='*' to='/login' />
      </Switch>
    </AuthLayout>
  )
}
