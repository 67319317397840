import React, { useState, useEffect } from 'react'
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Button, Input, PollResult, PageContainer, PageContent, PageActions, Dialog } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import PollForm from '../../../Components/Poll/PollForm'
import ExcelIcon from '../../../Assets/Icons/excel.svg'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  verticalSpacer: {
    height: 30
  },
  publicationContainer: {
    padding: '2rem 1rem 1rem',
    backgroundColor: Colors.cardBackgroundColor,
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: 5,
    marginBottom: '1rem'
  },
  line: {
    width: '100%',
    height: 1,
    background: Colors.lightBorder
  },
  editWarning: {
    backgroundColor: Colors.red,
    color: Colors.white,
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: 5
  },
  card: {
    backgroundColor: Colors.cardBackgroundColor,
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1rem',
    marginBottom: '.75rem'
  },
  cardTitle: {
    fontSize: '1.5rem'
  },
  cardSubtitle: {
    fontWeight: 700,
    marginTop: '1rem',
    marginBottom: '.25rem'
  }
}))

const Poll = () => {
  const classes = useStyles()
  const { pollStore } = useStore()
  const { pollId }: any = useParams()

  useEffect(() => {
    pollStore.getPoll(pollId)
    pollStore.getPollAnswers(pollId)
  }, [])

  useEffect(() => {
    if (pollStore.poll) {
      setTitleFi(get(pollStore.poll, 'titleFi', ''))
      setTitleEn(get(pollStore.poll, 'titleEn', ''))
      setContentFi(get(pollStore.poll, 'contentFi', ''))
      setContentEn(get(pollStore.poll, 'contentEn', ''))
      setQuestions(get(pollStore.poll, 'questions', []))
      setDraft(get(pollStore.poll, 'draft', false))
      setValidFrom(get(pollStore.poll, 'validFrom') ? moment(get(pollStore.poll, 'validFrom')).format('YYYY-MM-DD') : null)
      setValidUntil(get(pollStore.poll, 'validUntil') ? moment(get(pollStore.poll, 'validUntil')).format('YYYY-MM-DD') : null)
    }
  }, [pollStore.poll])

  const [tab, setTab] = useState(0)

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [validFrom, setValidFrom] = useState(null)
  const [validUntil, setValidUntil] = useState(null)
  const [questions, setQuestions] = useState([])
  const [alertVisible, setAlertVisible] = useState(false)
  const [draft, setDraft] = useState(false)

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const selectTab = (evt, value) => setTab(value)

  const handleSetValidFrom = (val) => setValidFrom(val)
  const handleSetValidUntil = (val) => setValidUntil(val)

  const { t } = useTranslation()

  const updatePoll = () => {
    pollStore.updatePoll(pollId, {
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      questions,
      draft
    })
  }

  const toggleDelete = () => {
    pollStore.updatePoll(pollId, {
      active: !pollStore.poll.active
    })
    closeAlert()
  }

  const renderDelete = () => {
    if (pollStore.poll) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={pollStore.poll.active ? t('delete_poll') : t('activate_poll')}
            onClick={openAlert}
            secondary
            margin
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  const renderDraftCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!!draft}
            onChange={() => setDraft(!draft)}
            name={t('save_as_draft')}
          />
        }
        label={t('save_as_draft')}
      />
    )
  }

  const renderContent = () => {
    if (tab === 0) {
      // Analytics
      if (!pollStore.poll || !pollStore.pollAnswers) return null
      return (
        <>
          <PageContent>
            <PollResult
              poll={pollStore.poll}
              pollAnswers={pollStore.pollAnswers}
            />
          </PageContent>
          <PageActions>
            <div className={classes.card}>
              <div className={classes.cardTitle}>
                {t('poll_summary')}
              </div>

              <div className={classes.cardSubtitle}>
                {t('valid_from')}
              </div>
              <div>
                {validFrom ? moment(validFrom).format('DD.MM.YYYY') : null}
              </div>

              <div className={classes.cardSubtitle}>
                {t('valid_until')}
              </div>
              <div>
                {validUntil ? moment(validUntil).format('DD.MM.YYYY') : null}
              </div>

              <div className={classes.cardSubtitle}>
                {t('answer_count_alt')}
              </div>
              <div>
                {pollStore.poll ? pollStore.poll.totalAnswers : null}
              </div>
            </div>

            <Button
              text={t('download_results')}
              onClick={() => pollStore.exportPoll(pollId)}
              leftIcon={ExcelIcon}
              disabled={!get(pollStore.poll, 'totalAnswers', 0)}
              margin
              fullWidth
              secondary
            />
          </PageActions>
        </>
      )
    }
    return (
      <>
        <PageContent disabled={!get(pollStore.poll, 'active')}>
          <div className={classes.editWarning}>{t('poll_edit_warning')}</div>
          <PollForm
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentFi={contentFi}
            setContentFi={setContentFi}
            contentEn={contentEn}
            setContentEn={setContentEn}
            questions={questions}
            setQuestions={setQuestions}
            submitted
          />
        </PageContent>
        <PageActions>
          <p className={[classes.text, classes.boldText].join(' ')}>
            {t('publication')}
          </p>
          <div className={classes.publicationContainer}>
            <Input
              type='date'
              label={t('valid_from')}
              value={validFrom}
              onChange={handleSetValidFrom}
            />
            <Input
              type='date'
              label={t('valid_until')}
              value={validUntil}
              onChange={handleSetValidUntil}
            />
          </div>
          {renderDraftCheckbox()}
          <Button
            text={t('save')}
            onClick={() => updatePoll()}
            margin
            fullWidth
          />
          {renderDelete()}
        </PageActions>
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={t('poll_details')}
        tabs={[
          { id: 0, label: t('poll_analytics') },
          { id: 1, label: t('edit_poll') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        {renderContent()}
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(pollStore.poll, 'active') ? t('delete_poll_title') : t('activate_poll_title')}
        description={get(pollStore.poll, 'active') ? t('delete_poll_description') : t('activate_poll_description')}
      />
    </>
  )
}

export default observer(Poll)
