import React from 'react'
import Chart from 'react-apexcharts'

export default function DonutChart (props) {

  const getOptions = () => {
    return {
      chart: {
        type: 'donut'
      },
      ...(props.options || {})
    }
  }

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={getOptions()}
            series={props.series}
            //labels={props.labels}
            type='donut'
            width="500"
            height="500"
          />
        </div>
      </div>
    </div>
  )

}

